import { default as _91_46_46_46slug_93Ra3icQKFsSMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/[...slug].vue?macro=true";
import { default as indexoc758woAMMMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/dashboard/index.vue?macro=true";
import { default as inspectionsTWImBr3Y61Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/dashboard/inspections.vue?macro=true";
import { default as vehiclesAMqD0mDoA4Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/dashboard/vehicles.vue?macro=true";
import { default as warrantiespwOVgKd2dVMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/dashboard/warranties.vue?macro=true";
import { default as indexPN6ZjWlQQvMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/evaluations/[id]/index.vue?macro=true";
import { default as indexRW6EjN4b08Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/evaluations/index.vue?macro=true";
import { default as ContentZFyl2ZG04NMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/impartiality-policy/components/Content.vue?macro=true";
import { default as SinglePostHeroM10nMPVzLHMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/impartiality-policy/components/SinglePostHero.vue?macro=true";
import { default as indexbppzW4IweMMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/impartiality-policy/index.vue?macro=true";
import { default as indextQeZ7O1Wi6Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/index.vue?macro=true";
import { default as index52KKOWeJLiMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/inspection/index.vue?macro=true";
import { default as About1Footer2U0dDV6lQ8Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/About1Footer.vue?macro=true";
import { default as About1Hero6e7mE905w7Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/About1Hero.vue?macro=true";
import { default as Awards440PC5nWqoMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Awards.vue?macro=true";
import { default as Benefitskpe35MSMM8Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Benefits.vue?macro=true";
import { default as Contactyg6VwnhgHGMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Contact.vue?macro=true";
import { default as SocialNetworkgIeCC4a0SgMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/SocialNetwork.vue?macro=true";
import { default as Team5aDGTIUkaYMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Team.vue?macro=true";
import { default as TeamCardynfIkeC57AMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/TeamCard.vue?macro=true";
import { default as Testimonial0LT0Uo3lMBMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Testimonial.vue?macro=true";
import { default as TestimonialCardAcXYwNuVqtMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/TestimonialCard.vue?macro=true";
import { default as VideoReelcxY6VK3Pm0Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/VideoReel.vue?macro=true";
import { default as datal6fWvX2R1oMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/data.ts?macro=true";
import { default as index1teso7K6gtMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/index.vue?macro=true";
import { default as typedFhI9HSRv5Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/type.ts?macro=true";
import { default as AppFeatureQHtCTsPoNkMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/AppFeature.vue?macro=true";
import { default as FaqsCj1yy8xfG1Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Faqs.vue?macro=true";
import { default as Featuresy2J4QJqpIFMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Features.vue?macro=true";
import { default as OpportunitiesiThyc8CdeYMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Opportunities.vue?macro=true";
import { default as PlanComparison82p2SLWywBMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/PlanComparison.vue?macro=true";
import { default as PricingX0XN8iGB6yMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Pricing.vue?macro=true";
import { default as Saas3Hero6tTkOcpNGnMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Saas3Hero.vue?macro=true";
import { default as Testimonialfp1hOCiudWMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Testimonial.vue?macro=true";
import { default as TestimonialCardKdlisbOATuMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/TestimonialCard.vue?macro=true";
import { default as WorkVq0KjqZ3XwMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Work.vue?macro=true";
import { default as dataW9J4VsseRKMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/data.ts?macro=true";
import { default as indexP9oeKS9JGlMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/index.vue?macro=true";
import { default as typeaZSDZfLTV3Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/type.ts?macro=true";
import { default as Contacts1FooteriTvXRUtgiUMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/components/Contacts1Footer.vue?macro=true";
import { default as Contacts1Hero9ZpeJIfj6iMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/components/Contacts1Hero.vue?macro=true";
import { default as DetailsYbX19Dgo54Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/components/Details.vue?macro=true";
import { default as FaqsUKQddeYyw6Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/components/Faqs.vue?macro=true";
import { default as dataVIrwuTaljMMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/data.ts?macro=true";
import { default as indexZiZwKhpXpfMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/index.vue?macro=true";
import { default as Brands9vTLmHSsllMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Brands.vue?macro=true";
import { default as ContactH1cb0tp82eMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Contact.vue?macro=true";
import { default as CovidBannernUFra0AOpZMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/CovidBanner.vue?macro=true";
import { default as Feature4TnIerSunfMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Feature.vue?macro=true";
import { default as FinancialFooter2kjZudVeatMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/FinancialFooter.vue?macro=true";
import { default as FinancialHeroRaD2xU98qYMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/FinancialHero.vue?macro=true";
import { default as IndustrieSwipert6liB6K3zxMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/IndustrieSwiper.vue?macro=true";
import { default as NewsCarddyvYJ29DrRMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/NewsCard.vue?macro=true";
import { default as NewsSwiperWa6rw7TzFHMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/NewsSwiper.vue?macro=true";
import { default as PricingTX6lFoltFwMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Pricing.vue?macro=true";
import { default as PurchaseyyXGUFgcZ1Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Purchase.vue?macro=true";
import { default as ServicesYCq4ocL1s5Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Services.vue?macro=true";
import { default as SponsorseFeVJAyviKMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Sponsors.vue?macro=true";
import { default as Testimonialv9hMsFFseGMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Testimonial.vue?macro=true";
import { default as TestimonialCardQPXSOWqa3lMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/TestimonialCard.vue?macro=true";
import { default as data7H1M6pZlCmMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/data.ts?macro=true";
import { default as indexXvgRKO5y2RMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/index.vue?macro=true";
import { default as evaluation_45typesfH1ChmSM9eMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/types/evaluation-types.ts?macro=true";
import { default as indexdM8ekW8n3CMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/inspection/index.vue?macro=true";
import { default as ContactJ5z71g6InjMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Contact.vue?macro=true";
import { default as Ctap7Z9I7MB66Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Cta.vue?macro=true";
import { default as Feature9FfTWBh4IVMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Feature.vue?macro=true";
import { default as FeatureCardj02zdsIsOxMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/FeatureCard.vue?macro=true";
import { default as MedicalFooteryYA1XZlAbkMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/MedicalFooter.vue?macro=true";
import { default as MedicalHero5O8ENOzEmMMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/MedicalHero.vue?macro=true";
import { default as NewsmMEmof5qQ9Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/News.vue?macro=true";
import { default as NewsCard8zUbowMfbUMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/NewsCard.vue?macro=true";
import { default as ServiceCardFffnkL3FGWMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/ServiceCard.vue?macro=true";
import { default as ServicesXvCmVyKpyYMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Services.vue?macro=true";
import { default as TeamGDxPwGXJIeMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Team.vue?macro=true";
import { default as TeamCardC6LlJsX8f5Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/TeamCard.vue?macro=true";
import { default as Testimonial6Uuj9xYCQtMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Testimonial.vue?macro=true";
import { default as TestimonialCardmhSlfsxtV1Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/TestimonialCard.vue?macro=true";
import { default as VideoReelpliCr3lQE3Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/VideoReel.vue?macro=true";
import { default as dataMRlcWdBzwZMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/data.ts?macro=true";
import { default as indexzXD8Mn47qcMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/index.vue?macro=true";
import { default as typewx8zdUDdHIMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/type.ts?macro=true";
import { default as imageorPtZeStrRMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/assets/data/image.ts?macro=true";
import { default as BrandssKSh2HCD9OMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Brands.vue?macro=true";
import { default as CtaR61QSmjlimMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Cta.vue?macro=true";
import { default as FaqsVhzkApyfbaMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Faqs.vue?macro=true";
import { default as Features1VmNdHf2OysMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Features1.vue?macro=true";
import { default as Features2lrk4uCH9dGMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Features2.vue?macro=true";
import { default as MobileApp1FooterSLw0TQK1zJMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/MobileApp1Footer.vue?macro=true";
import { default as MobileApp1HeroBSgSta33UZMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/MobileApp1Hero.vue?macro=true";
import { default as ProcessSwiperLLIllwoUU7Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/ProcessSwiper.vue?macro=true";
import { default as TestimonialdSvW8oBJf0Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Testimonial.vue?macro=true";
import { default as TestimonialCard5wbwh7r6fbMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/TestimonialCard.vue?macro=true";
import { default as datajGDHrgGeOkMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/data.ts?macro=true";
import { default as indexSRaN2hBorqMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/index.vue?macro=true";
import { default as AnchorNavigationbJNda9ZYcGMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue?macro=true";
import { default as BreadcrumbpcudvthJY0Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue?macro=true";
import { default as CustomSyntax5j544GQAWdMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue?macro=true";
import { default as JarallaxqUgdiJscplMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue?macro=true";
import { default as LightGalleryah8gNdmOiIMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue?macro=true";
import { default as LoadingSpinnerSPihv8kjm2Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue?macro=true";
import { default as ParallaxGSPX45pr1uMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue?macro=true";
import { default as PasswordInputHDI349mbFvMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue?macro=true";
import { default as RellaxIYD6FrDrVlMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue?macro=true";
import { default as VanillaTilthvc7EBi3koMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue?macro=true";
import { default as VideoPopup0IVuRDTRwAMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue?macro=true";
import { default as indexUFaoH6FPGyMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/sales/index.vue?macro=true";
import { default as indexKx063EsXi6Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty-form/index.vue?macro=true";
import { default as AppFeaturezBnVBCHCvyMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/AppFeature.vue?macro=true";
import { default as PricingX5hRnkNercMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/Pricing.vue?macro=true";
import { default as Saas3HerowHIgJJW6FKMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/Saas3Hero.vue?macro=true";
import { default as TestimonialDSdplPAPSvMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/Testimonial.vue?macro=true";
import { default as TestimonialCardPjOAtwDWhJMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/TestimonialCard.vue?macro=true";
import { default as WorkBETETI8ZMtMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/Work.vue?macro=true";
import { default as dataWcTVbE3j24Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/data.ts?macro=true";
import { default as indexopsNP9FLiAMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/index.vue?macro=true";
import { default as typekAeHIjH0mTMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/type.ts?macro=true";
import { default as ArticleCardeld0eBRKSHMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/ArticleCard.vue?macro=true";
import { default as ArticlesnZ77JJ6N2sMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/Articles.vue?macro=true";
import { default as CommentiFkFS2GFqCMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/Comment.vue?macro=true";
import { default as CommentForm6E1bl4JERrMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/CommentForm.vue?macro=true";
import { default as ContenttLUqNO3RMOMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/Content.vue?macro=true";
import { default as Jarallax1R79W9n0ftMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/Jarallax.vue?macro=true";
import { default as LightGallery37oKCSwomnMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/LightGallery.vue?macro=true";
import { default as SinglePostFooterlOJIM5Sj4KMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/SinglePostFooter.vue?macro=true";
import { default as SinglePostHerouDYE4aw2dCMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/SinglePostHero.vue?macro=true";
import { default as data79eIOOBPQkMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/data.ts?macro=true";
import { default as indexFCcGGSFe6HMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/index.vue?macro=true";
import { default as typeZfo3F7NOt5Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/type.ts?macro=true";
import { default as BlogCardirwuVjodgHMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/components/BlogCard.vue?macro=true";
import { default as ContentPABGg5TfN3Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/components/Content.vue?macro=true";
import { default as Footer8YYBjbBsfEMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/components/Footer.vue?macro=true";
import { default as PaginationMACmWx2ISEMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/components/Pagination.vue?macro=true";
import { default as dataXAxu47lnqXMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/data.ts?macro=true";
import { default as index2PcF38ZZeFMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/index.vue?macro=true";
import { default as single_45newsf9S7Y48DYAMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/single-news.vue?macro=true";
import { default as typesaWI5m2R9RMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/type.ts?macro=true";
import { default as indextmrBhIBcQqMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/payment/redirect/index.vue?macro=true";
import { default as ContentAXnGm6LbN4Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/privacy-policy/components/Content.vue?macro=true";
import { default as SinglePostHeronOsIzI9KCpMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/privacy-policy/components/SinglePostHero.vue?macro=true";
import { default as indexmDPGqZBdE7Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/privacy-policy/index.vue?macro=true";
import { default as indexJi7Ajbh5EzMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/sales-agent-form/index.vue?macro=true";
import { default as indexqOHKyRbo90Meta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/sales-agent-form2/index.vue?macro=true";
import { default as ContentXraZyiSuWqMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/terms-and-condition/components/Content.vue?macro=true";
import { default as SinglePostHeroohNBG9eu5SMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/terms-and-condition/components/SinglePostHero.vue?macro=true";
import { default as indexOJ58p4k8XsMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/terms-and-condition/index.vue?macro=true";
import { default as indexDoBjozyl6iMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/test/index.vue?macro=true";
import { default as indexbriGF3aFMMMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/trim/index.vue?macro=true";
import { default as indexowFwUi0ETTMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/warranty-form/index.vue?macro=true";
import { default as indextZv3K34zOnMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/warranty-form2/index.vue?macro=true";
import { default as indexpJzrIC3hAZMeta } from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/warranty/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Ra3icQKFsSMeta?.name ?? "slug___ar",
    path: _91_46_46_46slug_93Ra3icQKFsSMeta?.path ?? "/ar/:slug(.*)*",
    meta: _91_46_46_46slug_93Ra3icQKFsSMeta || {},
    alias: _91_46_46_46slug_93Ra3icQKFsSMeta?.alias || [],
    redirect: _91_46_46_46slug_93Ra3icQKFsSMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexoc758woAMMMeta?.name ?? "dashboard___ar",
    path: indexoc758woAMMMeta?.path ?? "/ar/dashboard",
    meta: indexoc758woAMMMeta || {},
    alias: indexoc758woAMMMeta?.alias || [],
    redirect: indexoc758woAMMMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: inspectionsTWImBr3Y61Meta?.name ?? "dashboard-inspections___ar",
    path: inspectionsTWImBr3Y61Meta?.path ?? "/ar/dashboard/inspections",
    meta: inspectionsTWImBr3Y61Meta || {},
    alias: inspectionsTWImBr3Y61Meta?.alias || [],
    redirect: inspectionsTWImBr3Y61Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/dashboard/inspections.vue").then(m => m.default || m)
  },
  {
    name: vehiclesAMqD0mDoA4Meta?.name ?? "dashboard-vehicles___ar",
    path: vehiclesAMqD0mDoA4Meta?.path ?? "/ar/dashboard/vehicles",
    meta: vehiclesAMqD0mDoA4Meta || {},
    alias: vehiclesAMqD0mDoA4Meta?.alias || [],
    redirect: vehiclesAMqD0mDoA4Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/dashboard/vehicles.vue").then(m => m.default || m)
  },
  {
    name: warrantiespwOVgKd2dVMeta?.name ?? "dashboard-warranties___ar",
    path: warrantiespwOVgKd2dVMeta?.path ?? "/ar/dashboard/warranties",
    meta: warrantiespwOVgKd2dVMeta || {},
    alias: warrantiespwOVgKd2dVMeta?.alias || [],
    redirect: warrantiespwOVgKd2dVMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/dashboard/warranties.vue").then(m => m.default || m)
  },
  {
    name: indexPN6ZjWlQQvMeta?.name ?? "evaluations-id___ar",
    path: indexPN6ZjWlQQvMeta?.path ?? "/ar/evaluations/:id()",
    meta: indexPN6ZjWlQQvMeta || {},
    alias: indexPN6ZjWlQQvMeta?.alias || [],
    redirect: indexPN6ZjWlQQvMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/evaluations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRW6EjN4b08Meta?.name ?? "evaluations___ar",
    path: indexRW6EjN4b08Meta?.path ?? "/ar/evaluations",
    meta: indexRW6EjN4b08Meta || {},
    alias: indexRW6EjN4b08Meta?.alias || [],
    redirect: indexRW6EjN4b08Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/evaluations/index.vue").then(m => m.default || m)
  },
  {
    name: ContentZFyl2ZG04NMeta?.name ?? "impartiality-policy-components-Content___ar",
    path: ContentZFyl2ZG04NMeta?.path ?? "/ar/impartiality-policy/components/Content",
    meta: ContentZFyl2ZG04NMeta || {},
    alias: ContentZFyl2ZG04NMeta?.alias || [],
    redirect: ContentZFyl2ZG04NMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/impartiality-policy/components/Content.vue").then(m => m.default || m)
  },
  {
    name: SinglePostHeroM10nMPVzLHMeta?.name ?? "impartiality-policy-components-SinglePostHero___ar",
    path: SinglePostHeroM10nMPVzLHMeta?.path ?? "/ar/impartiality-policy/components/SinglePostHero",
    meta: SinglePostHeroM10nMPVzLHMeta || {},
    alias: SinglePostHeroM10nMPVzLHMeta?.alias || [],
    redirect: SinglePostHeroM10nMPVzLHMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/impartiality-policy/components/SinglePostHero.vue").then(m => m.default || m)
  },
  {
    name: indexbppzW4IweMMeta?.name ?? "impartiality-policy___ar",
    path: indexbppzW4IweMMeta?.path ?? "/ar/impartiality-policy",
    meta: indexbppzW4IweMMeta || {},
    alias: indexbppzW4IweMMeta?.alias || [],
    redirect: indexbppzW4IweMMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/impartiality-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indextQeZ7O1Wi6Meta?.name ?? "index___ar",
    path: indextQeZ7O1Wi6Meta?.path ?? "/ar",
    meta: indextQeZ7O1Wi6Meta || {},
    alias: indextQeZ7O1Wi6Meta?.alias || [],
    redirect: indextQeZ7O1Wi6Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index52KKOWeJLiMeta?.name ?? "inspection___ar",
    path: index52KKOWeJLiMeta?.path ?? "/ar/inspection",
    meta: index52KKOWeJLiMeta || {},
    alias: index52KKOWeJLiMeta?.alias || [],
    redirect: index52KKOWeJLiMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/inspection/index.vue").then(m => m.default || m)
  },
  {
    name: About1Footer2U0dDV6lQ8Meta?.name ?? "landing-about-components-About1Footer___ar",
    path: About1Footer2U0dDV6lQ8Meta?.path ?? "/ar/landing/about/components/About1Footer",
    meta: About1Footer2U0dDV6lQ8Meta || {},
    alias: About1Footer2U0dDV6lQ8Meta?.alias || [],
    redirect: About1Footer2U0dDV6lQ8Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/About1Footer.vue").then(m => m.default || m)
  },
  {
    name: About1Hero6e7mE905w7Meta?.name ?? "landing-about-components-About1Hero___ar",
    path: About1Hero6e7mE905w7Meta?.path ?? "/ar/landing/about/components/About1Hero",
    meta: About1Hero6e7mE905w7Meta || {},
    alias: About1Hero6e7mE905w7Meta?.alias || [],
    redirect: About1Hero6e7mE905w7Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/About1Hero.vue").then(m => m.default || m)
  },
  {
    name: Awards440PC5nWqoMeta?.name ?? "landing-about-components-Awards___ar",
    path: Awards440PC5nWqoMeta?.path ?? "/ar/landing/about/components/Awards",
    meta: Awards440PC5nWqoMeta || {},
    alias: Awards440PC5nWqoMeta?.alias || [],
    redirect: Awards440PC5nWqoMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Awards.vue").then(m => m.default || m)
  },
  {
    name: Benefitskpe35MSMM8Meta?.name ?? "landing-about-components-Benefits___ar",
    path: Benefitskpe35MSMM8Meta?.path ?? "/ar/landing/about/components/Benefits",
    meta: Benefitskpe35MSMM8Meta || {},
    alias: Benefitskpe35MSMM8Meta?.alias || [],
    redirect: Benefitskpe35MSMM8Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Benefits.vue").then(m => m.default || m)
  },
  {
    name: Contactyg6VwnhgHGMeta?.name ?? "landing-about-components-Contact___ar",
    path: Contactyg6VwnhgHGMeta?.path ?? "/ar/landing/about/components/Contact",
    meta: Contactyg6VwnhgHGMeta || {},
    alias: Contactyg6VwnhgHGMeta?.alias || [],
    redirect: Contactyg6VwnhgHGMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: SocialNetworkgIeCC4a0SgMeta?.name ?? "landing-about-components-SocialNetwork___ar",
    path: SocialNetworkgIeCC4a0SgMeta?.path ?? "/ar/landing/about/components/SocialNetwork",
    meta: SocialNetworkgIeCC4a0SgMeta || {},
    alias: SocialNetworkgIeCC4a0SgMeta?.alias || [],
    redirect: SocialNetworkgIeCC4a0SgMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/SocialNetwork.vue").then(m => m.default || m)
  },
  {
    name: Team5aDGTIUkaYMeta?.name ?? "landing-about-components-Team___ar",
    path: Team5aDGTIUkaYMeta?.path ?? "/ar/landing/about/components/Team",
    meta: Team5aDGTIUkaYMeta || {},
    alias: Team5aDGTIUkaYMeta?.alias || [],
    redirect: Team5aDGTIUkaYMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Team.vue").then(m => m.default || m)
  },
  {
    name: TeamCardynfIkeC57AMeta?.name ?? "landing-about-components-TeamCard___ar",
    path: TeamCardynfIkeC57AMeta?.path ?? "/ar/landing/about/components/TeamCard",
    meta: TeamCardynfIkeC57AMeta || {},
    alias: TeamCardynfIkeC57AMeta?.alias || [],
    redirect: TeamCardynfIkeC57AMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/TeamCard.vue").then(m => m.default || m)
  },
  {
    name: Testimonial0LT0Uo3lMBMeta?.name ?? "landing-about-components-Testimonial___ar",
    path: Testimonial0LT0Uo3lMBMeta?.path ?? "/ar/landing/about/components/Testimonial",
    meta: Testimonial0LT0Uo3lMBMeta || {},
    alias: Testimonial0LT0Uo3lMBMeta?.alias || [],
    redirect: Testimonial0LT0Uo3lMBMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardAcXYwNuVqtMeta?.name ?? "landing-about-components-TestimonialCard___ar",
    path: TestimonialCardAcXYwNuVqtMeta?.path ?? "/ar/landing/about/components/TestimonialCard",
    meta: TestimonialCardAcXYwNuVqtMeta || {},
    alias: TestimonialCardAcXYwNuVqtMeta?.alias || [],
    redirect: TestimonialCardAcXYwNuVqtMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: VideoReelcxY6VK3Pm0Meta?.name ?? "landing-about-components-VideoReel___ar",
    path: VideoReelcxY6VK3Pm0Meta?.path ?? "/ar/landing/about/components/VideoReel",
    meta: VideoReelcxY6VK3Pm0Meta || {},
    alias: VideoReelcxY6VK3Pm0Meta?.alias || [],
    redirect: VideoReelcxY6VK3Pm0Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/components/VideoReel.vue").then(m => m.default || m)
  },
  {
    name: datal6fWvX2R1oMeta?.name ?? "landing-about-data___ar",
    path: datal6fWvX2R1oMeta?.path ?? "/ar/landing/about/data",
    meta: datal6fWvX2R1oMeta || {},
    alias: datal6fWvX2R1oMeta?.alias || [],
    redirect: datal6fWvX2R1oMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/data.ts").then(m => m.default || m)
  },
  {
    name: index1teso7K6gtMeta?.name ?? "landing-about___ar",
    path: index1teso7K6gtMeta?.path ?? "/ar/landing/about",
    meta: index1teso7K6gtMeta || {},
    alias: index1teso7K6gtMeta?.alias || [],
    redirect: index1teso7K6gtMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/index.vue").then(m => m.default || m)
  },
  {
    name: typedFhI9HSRv5Meta?.name ?? "landing-about-type___ar",
    path: typedFhI9HSRv5Meta?.path ?? "/ar/landing/about/type",
    meta: typedFhI9HSRv5Meta || {},
    alias: typedFhI9HSRv5Meta?.alias || [],
    redirect: typedFhI9HSRv5Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/about/type.ts").then(m => m.default || m)
  },
  {
    name: AppFeatureQHtCTsPoNkMeta?.name ?? "landing-cartrust-agent-components-AppFeature___ar",
    path: AppFeatureQHtCTsPoNkMeta?.path ?? "/ar/landing/cartrust-agent/components/AppFeature",
    meta: AppFeatureQHtCTsPoNkMeta || {},
    alias: AppFeatureQHtCTsPoNkMeta?.alias || [],
    redirect: AppFeatureQHtCTsPoNkMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/AppFeature.vue").then(m => m.default || m)
  },
  {
    name: FaqsCj1yy8xfG1Meta?.name ?? "landing-cartrust-agent-components-Faqs___ar",
    path: FaqsCj1yy8xfG1Meta?.path ?? "/ar/landing/cartrust-agent/components/Faqs",
    meta: FaqsCj1yy8xfG1Meta || {},
    alias: FaqsCj1yy8xfG1Meta?.alias || [],
    redirect: FaqsCj1yy8xfG1Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: Featuresy2J4QJqpIFMeta?.name ?? "landing-cartrust-agent-components-Features___ar",
    path: Featuresy2J4QJqpIFMeta?.path ?? "/ar/landing/cartrust-agent/components/Features",
    meta: Featuresy2J4QJqpIFMeta || {},
    alias: Featuresy2J4QJqpIFMeta?.alias || [],
    redirect: Featuresy2J4QJqpIFMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Features.vue").then(m => m.default || m)
  },
  {
    name: OpportunitiesiThyc8CdeYMeta?.name ?? "landing-cartrust-agent-components-Opportunities___ar",
    path: OpportunitiesiThyc8CdeYMeta?.path ?? "/ar/landing/cartrust-agent/components/Opportunities",
    meta: OpportunitiesiThyc8CdeYMeta || {},
    alias: OpportunitiesiThyc8CdeYMeta?.alias || [],
    redirect: OpportunitiesiThyc8CdeYMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Opportunities.vue").then(m => m.default || m)
  },
  {
    name: PlanComparison82p2SLWywBMeta?.name ?? "landing-cartrust-agent-components-PlanComparison___ar",
    path: PlanComparison82p2SLWywBMeta?.path ?? "/ar/landing/cartrust-agent/components/PlanComparison",
    meta: PlanComparison82p2SLWywBMeta || {},
    alias: PlanComparison82p2SLWywBMeta?.alias || [],
    redirect: PlanComparison82p2SLWywBMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/PlanComparison.vue").then(m => m.default || m)
  },
  {
    name: PricingX0XN8iGB6yMeta?.name ?? "landing-cartrust-agent-components-Pricing___ar",
    path: PricingX0XN8iGB6yMeta?.path ?? "/ar/landing/cartrust-agent/components/Pricing",
    meta: PricingX0XN8iGB6yMeta || {},
    alias: PricingX0XN8iGB6yMeta?.alias || [],
    redirect: PricingX0XN8iGB6yMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Pricing.vue").then(m => m.default || m)
  },
  {
    name: Saas3Hero6tTkOcpNGnMeta?.name ?? "landing-cartrust-agent-components-Saas3Hero___ar",
    path: Saas3Hero6tTkOcpNGnMeta?.path ?? "/ar/landing/cartrust-agent/components/Saas3Hero",
    meta: Saas3Hero6tTkOcpNGnMeta || {},
    alias: Saas3Hero6tTkOcpNGnMeta?.alias || [],
    redirect: Saas3Hero6tTkOcpNGnMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Saas3Hero.vue").then(m => m.default || m)
  },
  {
    name: Testimonialfp1hOCiudWMeta?.name ?? "landing-cartrust-agent-components-Testimonial___ar",
    path: Testimonialfp1hOCiudWMeta?.path ?? "/ar/landing/cartrust-agent/components/Testimonial",
    meta: Testimonialfp1hOCiudWMeta || {},
    alias: Testimonialfp1hOCiudWMeta?.alias || [],
    redirect: Testimonialfp1hOCiudWMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardKdlisbOATuMeta?.name ?? "landing-cartrust-agent-components-TestimonialCard___ar",
    path: TestimonialCardKdlisbOATuMeta?.path ?? "/ar/landing/cartrust-agent/components/TestimonialCard",
    meta: TestimonialCardKdlisbOATuMeta || {},
    alias: TestimonialCardKdlisbOATuMeta?.alias || [],
    redirect: TestimonialCardKdlisbOATuMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: WorkVq0KjqZ3XwMeta?.name ?? "landing-cartrust-agent-components-Work___ar",
    path: WorkVq0KjqZ3XwMeta?.path ?? "/ar/landing/cartrust-agent/components/Work",
    meta: WorkVq0KjqZ3XwMeta || {},
    alias: WorkVq0KjqZ3XwMeta?.alias || [],
    redirect: WorkVq0KjqZ3XwMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/components/Work.vue").then(m => m.default || m)
  },
  {
    name: dataW9J4VsseRKMeta?.name ?? "landing-cartrust-agent-data___ar",
    path: dataW9J4VsseRKMeta?.path ?? "/ar/landing/cartrust-agent/data",
    meta: dataW9J4VsseRKMeta || {},
    alias: dataW9J4VsseRKMeta?.alias || [],
    redirect: dataW9J4VsseRKMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/data.ts").then(m => m.default || m)
  },
  {
    name: indexP9oeKS9JGlMeta?.name ?? "landing-cartrust-agent___ar",
    path: indexP9oeKS9JGlMeta?.path ?? "/ar/landing/cartrust-agent",
    meta: indexP9oeKS9JGlMeta || {},
    alias: indexP9oeKS9JGlMeta?.alias || [],
    redirect: indexP9oeKS9JGlMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/index.vue").then(m => m.default || m)
  },
  {
    name: typeaZSDZfLTV3Meta?.name ?? "landing-cartrust-agent-type___ar",
    path: typeaZSDZfLTV3Meta?.path ?? "/ar/landing/cartrust-agent/type",
    meta: typeaZSDZfLTV3Meta || {},
    alias: typeaZSDZfLTV3Meta?.alias || [],
    redirect: typeaZSDZfLTV3Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/cartrust-agent/type.ts").then(m => m.default || m)
  },
  {
    name: Contacts1FooteriTvXRUtgiUMeta?.name ?? "landing-contact-components-Contacts1Footer___ar",
    path: Contacts1FooteriTvXRUtgiUMeta?.path ?? "/ar/landing/contact/components/Contacts1Footer",
    meta: Contacts1FooteriTvXRUtgiUMeta || {},
    alias: Contacts1FooteriTvXRUtgiUMeta?.alias || [],
    redirect: Contacts1FooteriTvXRUtgiUMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/components/Contacts1Footer.vue").then(m => m.default || m)
  },
  {
    name: Contacts1Hero9ZpeJIfj6iMeta?.name ?? "landing-contact-components-Contacts1Hero___ar",
    path: Contacts1Hero9ZpeJIfj6iMeta?.path ?? "/ar/landing/contact/components/Contacts1Hero",
    meta: Contacts1Hero9ZpeJIfj6iMeta || {},
    alias: Contacts1Hero9ZpeJIfj6iMeta?.alias || [],
    redirect: Contacts1Hero9ZpeJIfj6iMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/components/Contacts1Hero.vue").then(m => m.default || m)
  },
  {
    name: DetailsYbX19Dgo54Meta?.name ?? "landing-contact-components-Details___ar",
    path: DetailsYbX19Dgo54Meta?.path ?? "/ar/landing/contact/components/Details",
    meta: DetailsYbX19Dgo54Meta || {},
    alias: DetailsYbX19Dgo54Meta?.alias || [],
    redirect: DetailsYbX19Dgo54Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/components/Details.vue").then(m => m.default || m)
  },
  {
    name: FaqsUKQddeYyw6Meta?.name ?? "landing-contact-components-Faqs___ar",
    path: FaqsUKQddeYyw6Meta?.path ?? "/ar/landing/contact/components/Faqs",
    meta: FaqsUKQddeYyw6Meta || {},
    alias: FaqsUKQddeYyw6Meta?.alias || [],
    redirect: FaqsUKQddeYyw6Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: dataVIrwuTaljMMeta?.name ?? "landing-contact-data___ar",
    path: dataVIrwuTaljMMeta?.path ?? "/ar/landing/contact/data",
    meta: dataVIrwuTaljMMeta || {},
    alias: dataVIrwuTaljMMeta?.alias || [],
    redirect: dataVIrwuTaljMMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/data.ts").then(m => m.default || m)
  },
  {
    name: indexZiZwKhpXpfMeta?.name ?? "landing-contact___ar",
    path: indexZiZwKhpXpfMeta?.path ?? "/ar/landing/contact",
    meta: indexZiZwKhpXpfMeta || {},
    alias: indexZiZwKhpXpfMeta?.alias || [],
    redirect: indexZiZwKhpXpfMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/contact/index.vue").then(m => m.default || m)
  },
  {
    name: Brands9vTLmHSsllMeta?.name ?? "landing-evaluation-components-Brands___ar",
    path: Brands9vTLmHSsllMeta?.path ?? "/ar/landing/evaluation/components/Brands",
    meta: Brands9vTLmHSsllMeta || {},
    alias: Brands9vTLmHSsllMeta?.alias || [],
    redirect: Brands9vTLmHSsllMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Brands.vue").then(m => m.default || m)
  },
  {
    name: ContactH1cb0tp82eMeta?.name ?? "landing-evaluation-components-Contact___ar",
    path: ContactH1cb0tp82eMeta?.path ?? "/ar/landing/evaluation/components/Contact",
    meta: ContactH1cb0tp82eMeta || {},
    alias: ContactH1cb0tp82eMeta?.alias || [],
    redirect: ContactH1cb0tp82eMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: CovidBannernUFra0AOpZMeta?.name ?? "landing-evaluation-components-CovidBanner___ar",
    path: CovidBannernUFra0AOpZMeta?.path ?? "/ar/landing/evaluation/components/CovidBanner",
    meta: CovidBannernUFra0AOpZMeta || {},
    alias: CovidBannernUFra0AOpZMeta?.alias || [],
    redirect: CovidBannernUFra0AOpZMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/CovidBanner.vue").then(m => m.default || m)
  },
  {
    name: Feature4TnIerSunfMeta?.name ?? "landing-evaluation-components-Feature___ar",
    path: Feature4TnIerSunfMeta?.path ?? "/ar/landing/evaluation/components/Feature",
    meta: Feature4TnIerSunfMeta || {},
    alias: Feature4TnIerSunfMeta?.alias || [],
    redirect: Feature4TnIerSunfMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Feature.vue").then(m => m.default || m)
  },
  {
    name: FinancialFooter2kjZudVeatMeta?.name ?? "landing-evaluation-components-FinancialFooter___ar",
    path: FinancialFooter2kjZudVeatMeta?.path ?? "/ar/landing/evaluation/components/FinancialFooter",
    meta: FinancialFooter2kjZudVeatMeta || {},
    alias: FinancialFooter2kjZudVeatMeta?.alias || [],
    redirect: FinancialFooter2kjZudVeatMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/FinancialFooter.vue").then(m => m.default || m)
  },
  {
    name: FinancialHeroRaD2xU98qYMeta?.name ?? "landing-evaluation-components-FinancialHero___ar",
    path: FinancialHeroRaD2xU98qYMeta?.path ?? "/ar/landing/evaluation/components/FinancialHero",
    meta: FinancialHeroRaD2xU98qYMeta || {},
    alias: FinancialHeroRaD2xU98qYMeta?.alias || [],
    redirect: FinancialHeroRaD2xU98qYMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/FinancialHero.vue").then(m => m.default || m)
  },
  {
    name: IndustrieSwipert6liB6K3zxMeta?.name ?? "landing-evaluation-components-IndustrieSwiper___ar",
    path: IndustrieSwipert6liB6K3zxMeta?.path ?? "/ar/landing/evaluation/components/IndustrieSwiper",
    meta: IndustrieSwipert6liB6K3zxMeta || {},
    alias: IndustrieSwipert6liB6K3zxMeta?.alias || [],
    redirect: IndustrieSwipert6liB6K3zxMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/IndustrieSwiper.vue").then(m => m.default || m)
  },
  {
    name: NewsCarddyvYJ29DrRMeta?.name ?? "landing-evaluation-components-NewsCard___ar",
    path: NewsCarddyvYJ29DrRMeta?.path ?? "/ar/landing/evaluation/components/NewsCard",
    meta: NewsCarddyvYJ29DrRMeta || {},
    alias: NewsCarddyvYJ29DrRMeta?.alias || [],
    redirect: NewsCarddyvYJ29DrRMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/NewsCard.vue").then(m => m.default || m)
  },
  {
    name: NewsSwiperWa6rw7TzFHMeta?.name ?? "landing-evaluation-components-NewsSwiper___ar",
    path: NewsSwiperWa6rw7TzFHMeta?.path ?? "/ar/landing/evaluation/components/NewsSwiper",
    meta: NewsSwiperWa6rw7TzFHMeta || {},
    alias: NewsSwiperWa6rw7TzFHMeta?.alias || [],
    redirect: NewsSwiperWa6rw7TzFHMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/NewsSwiper.vue").then(m => m.default || m)
  },
  {
    name: PricingTX6lFoltFwMeta?.name ?? "landing-evaluation-components-Pricing___ar",
    path: PricingTX6lFoltFwMeta?.path ?? "/ar/landing/evaluation/components/Pricing",
    meta: PricingTX6lFoltFwMeta || {},
    alias: PricingTX6lFoltFwMeta?.alias || [],
    redirect: PricingTX6lFoltFwMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Pricing.vue").then(m => m.default || m)
  },
  {
    name: PurchaseyyXGUFgcZ1Meta?.name ?? "landing-evaluation-components-Purchase___ar",
    path: PurchaseyyXGUFgcZ1Meta?.path ?? "/ar/landing/evaluation/components/Purchase",
    meta: PurchaseyyXGUFgcZ1Meta || {},
    alias: PurchaseyyXGUFgcZ1Meta?.alias || [],
    redirect: PurchaseyyXGUFgcZ1Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Purchase.vue").then(m => m.default || m)
  },
  {
    name: ServicesYCq4ocL1s5Meta?.name ?? "landing-evaluation-components-Services___ar",
    path: ServicesYCq4ocL1s5Meta?.path ?? "/ar/landing/evaluation/components/Services",
    meta: ServicesYCq4ocL1s5Meta || {},
    alias: ServicesYCq4ocL1s5Meta?.alias || [],
    redirect: ServicesYCq4ocL1s5Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Services.vue").then(m => m.default || m)
  },
  {
    name: SponsorseFeVJAyviKMeta?.name ?? "landing-evaluation-components-Sponsors___ar",
    path: SponsorseFeVJAyviKMeta?.path ?? "/ar/landing/evaluation/components/Sponsors",
    meta: SponsorseFeVJAyviKMeta || {},
    alias: SponsorseFeVJAyviKMeta?.alias || [],
    redirect: SponsorseFeVJAyviKMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Sponsors.vue").then(m => m.default || m)
  },
  {
    name: Testimonialv9hMsFFseGMeta?.name ?? "landing-evaluation-components-Testimonial___ar",
    path: Testimonialv9hMsFFseGMeta?.path ?? "/ar/landing/evaluation/components/Testimonial",
    meta: Testimonialv9hMsFFseGMeta || {},
    alias: Testimonialv9hMsFFseGMeta?.alias || [],
    redirect: Testimonialv9hMsFFseGMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardQPXSOWqa3lMeta?.name ?? "landing-evaluation-components-TestimonialCard___ar",
    path: TestimonialCardQPXSOWqa3lMeta?.path ?? "/ar/landing/evaluation/components/TestimonialCard",
    meta: TestimonialCardQPXSOWqa3lMeta || {},
    alias: TestimonialCardQPXSOWqa3lMeta?.alias || [],
    redirect: TestimonialCardQPXSOWqa3lMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: data7H1M6pZlCmMeta?.name ?? "landing-evaluation-data___ar",
    path: data7H1M6pZlCmMeta?.path ?? "/ar/landing/evaluation/data",
    meta: data7H1M6pZlCmMeta || {},
    alias: data7H1M6pZlCmMeta?.alias || [],
    redirect: data7H1M6pZlCmMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/data.ts").then(m => m.default || m)
  },
  {
    name: indexXvgRKO5y2RMeta?.name ?? "landing-evaluation___ar",
    path: indexXvgRKO5y2RMeta?.path ?? "/ar/landing/evaluation",
    meta: indexXvgRKO5y2RMeta || {},
    alias: indexXvgRKO5y2RMeta?.alias || [],
    redirect: indexXvgRKO5y2RMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: evaluation_45typesfH1ChmSM9eMeta?.name ?? "landing-evaluation-types-evaluation-types___ar",
    path: evaluation_45typesfH1ChmSM9eMeta?.path ?? "/ar/landing/evaluation/types/evaluation-types",
    meta: evaluation_45typesfH1ChmSM9eMeta || {},
    alias: evaluation_45typesfH1ChmSM9eMeta?.alias || [],
    redirect: evaluation_45typesfH1ChmSM9eMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/evaluation/types/evaluation-types.ts").then(m => m.default || m)
  },
  {
    name: indexdM8ekW8n3CMeta?.name ?? "landing-inspection___ar",
    path: indexdM8ekW8n3CMeta?.path ?? "/ar/landing/inspection",
    meta: indexdM8ekW8n3CMeta || {},
    alias: indexdM8ekW8n3CMeta?.alias || [],
    redirect: indexdM8ekW8n3CMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/inspection/index.vue").then(m => m.default || m)
  },
  {
    name: ContactJ5z71g6InjMeta?.name ?? "landing-medical-components-Contact___ar",
    path: ContactJ5z71g6InjMeta?.path ?? "/ar/landing/medical/components/Contact",
    meta: ContactJ5z71g6InjMeta || {},
    alias: ContactJ5z71g6InjMeta?.alias || [],
    redirect: ContactJ5z71g6InjMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: Ctap7Z9I7MB66Meta?.name ?? "landing-medical-components-Cta___ar",
    path: Ctap7Z9I7MB66Meta?.path ?? "/ar/landing/medical/components/Cta",
    meta: Ctap7Z9I7MB66Meta || {},
    alias: Ctap7Z9I7MB66Meta?.alias || [],
    redirect: Ctap7Z9I7MB66Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Cta.vue").then(m => m.default || m)
  },
  {
    name: Feature9FfTWBh4IVMeta?.name ?? "landing-medical-components-Feature___ar",
    path: Feature9FfTWBh4IVMeta?.path ?? "/ar/landing/medical/components/Feature",
    meta: Feature9FfTWBh4IVMeta || {},
    alias: Feature9FfTWBh4IVMeta?.alias || [],
    redirect: Feature9FfTWBh4IVMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Feature.vue").then(m => m.default || m)
  },
  {
    name: FeatureCardj02zdsIsOxMeta?.name ?? "landing-medical-components-FeatureCard___ar",
    path: FeatureCardj02zdsIsOxMeta?.path ?? "/ar/landing/medical/components/FeatureCard",
    meta: FeatureCardj02zdsIsOxMeta || {},
    alias: FeatureCardj02zdsIsOxMeta?.alias || [],
    redirect: FeatureCardj02zdsIsOxMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/FeatureCard.vue").then(m => m.default || m)
  },
  {
    name: MedicalFooteryYA1XZlAbkMeta?.name ?? "landing-medical-components-MedicalFooter___ar",
    path: MedicalFooteryYA1XZlAbkMeta?.path ?? "/ar/landing/medical/components/MedicalFooter",
    meta: MedicalFooteryYA1XZlAbkMeta || {},
    alias: MedicalFooteryYA1XZlAbkMeta?.alias || [],
    redirect: MedicalFooteryYA1XZlAbkMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/MedicalFooter.vue").then(m => m.default || m)
  },
  {
    name: MedicalHero5O8ENOzEmMMeta?.name ?? "landing-medical-components-MedicalHero___ar",
    path: MedicalHero5O8ENOzEmMMeta?.path ?? "/ar/landing/medical/components/MedicalHero",
    meta: MedicalHero5O8ENOzEmMMeta || {},
    alias: MedicalHero5O8ENOzEmMMeta?.alias || [],
    redirect: MedicalHero5O8ENOzEmMMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/MedicalHero.vue").then(m => m.default || m)
  },
  {
    name: NewsmMEmof5qQ9Meta?.name ?? "landing-medical-components-News___ar",
    path: NewsmMEmof5qQ9Meta?.path ?? "/ar/landing/medical/components/News",
    meta: NewsmMEmof5qQ9Meta || {},
    alias: NewsmMEmof5qQ9Meta?.alias || [],
    redirect: NewsmMEmof5qQ9Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/News.vue").then(m => m.default || m)
  },
  {
    name: NewsCard8zUbowMfbUMeta?.name ?? "landing-medical-components-NewsCard___ar",
    path: NewsCard8zUbowMfbUMeta?.path ?? "/ar/landing/medical/components/NewsCard",
    meta: NewsCard8zUbowMfbUMeta || {},
    alias: NewsCard8zUbowMfbUMeta?.alias || [],
    redirect: NewsCard8zUbowMfbUMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/NewsCard.vue").then(m => m.default || m)
  },
  {
    name: ServiceCardFffnkL3FGWMeta?.name ?? "landing-medical-components-ServiceCard___ar",
    path: ServiceCardFffnkL3FGWMeta?.path ?? "/ar/landing/medical/components/ServiceCard",
    meta: ServiceCardFffnkL3FGWMeta || {},
    alias: ServiceCardFffnkL3FGWMeta?.alias || [],
    redirect: ServiceCardFffnkL3FGWMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/ServiceCard.vue").then(m => m.default || m)
  },
  {
    name: ServicesXvCmVyKpyYMeta?.name ?? "landing-medical-components-Services___ar",
    path: ServicesXvCmVyKpyYMeta?.path ?? "/ar/landing/medical/components/Services",
    meta: ServicesXvCmVyKpyYMeta || {},
    alias: ServicesXvCmVyKpyYMeta?.alias || [],
    redirect: ServicesXvCmVyKpyYMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Services.vue").then(m => m.default || m)
  },
  {
    name: TeamGDxPwGXJIeMeta?.name ?? "landing-medical-components-Team___ar",
    path: TeamGDxPwGXJIeMeta?.path ?? "/ar/landing/medical/components/Team",
    meta: TeamGDxPwGXJIeMeta || {},
    alias: TeamGDxPwGXJIeMeta?.alias || [],
    redirect: TeamGDxPwGXJIeMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Team.vue").then(m => m.default || m)
  },
  {
    name: TeamCardC6LlJsX8f5Meta?.name ?? "landing-medical-components-TeamCard___ar",
    path: TeamCardC6LlJsX8f5Meta?.path ?? "/ar/landing/medical/components/TeamCard",
    meta: TeamCardC6LlJsX8f5Meta || {},
    alias: TeamCardC6LlJsX8f5Meta?.alias || [],
    redirect: TeamCardC6LlJsX8f5Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/TeamCard.vue").then(m => m.default || m)
  },
  {
    name: Testimonial6Uuj9xYCQtMeta?.name ?? "landing-medical-components-Testimonial___ar",
    path: Testimonial6Uuj9xYCQtMeta?.path ?? "/ar/landing/medical/components/Testimonial",
    meta: Testimonial6Uuj9xYCQtMeta || {},
    alias: Testimonial6Uuj9xYCQtMeta?.alias || [],
    redirect: Testimonial6Uuj9xYCQtMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardmhSlfsxtV1Meta?.name ?? "landing-medical-components-TestimonialCard___ar",
    path: TestimonialCardmhSlfsxtV1Meta?.path ?? "/ar/landing/medical/components/TestimonialCard",
    meta: TestimonialCardmhSlfsxtV1Meta || {},
    alias: TestimonialCardmhSlfsxtV1Meta?.alias || [],
    redirect: TestimonialCardmhSlfsxtV1Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: VideoReelpliCr3lQE3Meta?.name ?? "landing-medical-components-VideoReel___ar",
    path: VideoReelpliCr3lQE3Meta?.path ?? "/ar/landing/medical/components/VideoReel",
    meta: VideoReelpliCr3lQE3Meta || {},
    alias: VideoReelpliCr3lQE3Meta?.alias || [],
    redirect: VideoReelpliCr3lQE3Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/components/VideoReel.vue").then(m => m.default || m)
  },
  {
    name: dataMRlcWdBzwZMeta?.name ?? "landing-medical-data___ar",
    path: dataMRlcWdBzwZMeta?.path ?? "/ar/landing/medical/data",
    meta: dataMRlcWdBzwZMeta || {},
    alias: dataMRlcWdBzwZMeta?.alias || [],
    redirect: dataMRlcWdBzwZMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/data.ts").then(m => m.default || m)
  },
  {
    name: indexzXD8Mn47qcMeta?.name ?? "landing-medical___ar",
    path: indexzXD8Mn47qcMeta?.path ?? "/ar/landing/medical",
    meta: indexzXD8Mn47qcMeta || {},
    alias: indexzXD8Mn47qcMeta?.alias || [],
    redirect: indexzXD8Mn47qcMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/index.vue").then(m => m.default || m)
  },
  {
    name: typewx8zdUDdHIMeta?.name ?? "landing-medical-type___ar",
    path: typewx8zdUDdHIMeta?.path ?? "/ar/landing/medical/type",
    meta: typewx8zdUDdHIMeta || {},
    alias: typewx8zdUDdHIMeta?.alias || [],
    redirect: typewx8zdUDdHIMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/medical/type.ts").then(m => m.default || m)
  },
  {
    name: imageorPtZeStrRMeta?.name ?? "landing-mobile-app-1-assets-data-image___ar",
    path: imageorPtZeStrRMeta?.path ?? "/ar/landing/mobile-app-1/assets/data/image",
    meta: imageorPtZeStrRMeta || {},
    alias: imageorPtZeStrRMeta?.alias || [],
    redirect: imageorPtZeStrRMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/assets/data/image.ts").then(m => m.default || m)
  },
  {
    name: BrandssKSh2HCD9OMeta?.name ?? "landing-mobile-app-1-components-Brands___ar",
    path: BrandssKSh2HCD9OMeta?.path ?? "/ar/landing/mobile-app-1/components/Brands",
    meta: BrandssKSh2HCD9OMeta || {},
    alias: BrandssKSh2HCD9OMeta?.alias || [],
    redirect: BrandssKSh2HCD9OMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Brands.vue").then(m => m.default || m)
  },
  {
    name: CtaR61QSmjlimMeta?.name ?? "landing-mobile-app-1-components-Cta___ar",
    path: CtaR61QSmjlimMeta?.path ?? "/ar/landing/mobile-app-1/components/Cta",
    meta: CtaR61QSmjlimMeta || {},
    alias: CtaR61QSmjlimMeta?.alias || [],
    redirect: CtaR61QSmjlimMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Cta.vue").then(m => m.default || m)
  },
  {
    name: FaqsVhzkApyfbaMeta?.name ?? "landing-mobile-app-1-components-Faqs___ar",
    path: FaqsVhzkApyfbaMeta?.path ?? "/ar/landing/mobile-app-1/components/Faqs",
    meta: FaqsVhzkApyfbaMeta || {},
    alias: FaqsVhzkApyfbaMeta?.alias || [],
    redirect: FaqsVhzkApyfbaMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: Features1VmNdHf2OysMeta?.name ?? "landing-mobile-app-1-components-Features1___ar",
    path: Features1VmNdHf2OysMeta?.path ?? "/ar/landing/mobile-app-1/components/Features1",
    meta: Features1VmNdHf2OysMeta || {},
    alias: Features1VmNdHf2OysMeta?.alias || [],
    redirect: Features1VmNdHf2OysMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Features1.vue").then(m => m.default || m)
  },
  {
    name: Features2lrk4uCH9dGMeta?.name ?? "landing-mobile-app-1-components-Features2___ar",
    path: Features2lrk4uCH9dGMeta?.path ?? "/ar/landing/mobile-app-1/components/Features2",
    meta: Features2lrk4uCH9dGMeta || {},
    alias: Features2lrk4uCH9dGMeta?.alias || [],
    redirect: Features2lrk4uCH9dGMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Features2.vue").then(m => m.default || m)
  },
  {
    name: MobileApp1FooterSLw0TQK1zJMeta?.name ?? "landing-mobile-app-1-components-MobileApp1Footer___ar",
    path: MobileApp1FooterSLw0TQK1zJMeta?.path ?? "/ar/landing/mobile-app-1/components/MobileApp1Footer",
    meta: MobileApp1FooterSLw0TQK1zJMeta || {},
    alias: MobileApp1FooterSLw0TQK1zJMeta?.alias || [],
    redirect: MobileApp1FooterSLw0TQK1zJMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/MobileApp1Footer.vue").then(m => m.default || m)
  },
  {
    name: MobileApp1HeroBSgSta33UZMeta?.name ?? "landing-mobile-app-1-components-MobileApp1Hero___ar",
    path: MobileApp1HeroBSgSta33UZMeta?.path ?? "/ar/landing/mobile-app-1/components/MobileApp1Hero",
    meta: MobileApp1HeroBSgSta33UZMeta || {},
    alias: MobileApp1HeroBSgSta33UZMeta?.alias || [],
    redirect: MobileApp1HeroBSgSta33UZMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/MobileApp1Hero.vue").then(m => m.default || m)
  },
  {
    name: ProcessSwiperLLIllwoUU7Meta?.name ?? "landing-mobile-app-1-components-ProcessSwiper___ar",
    path: ProcessSwiperLLIllwoUU7Meta?.path ?? "/ar/landing/mobile-app-1/components/ProcessSwiper",
    meta: ProcessSwiperLLIllwoUU7Meta || {},
    alias: ProcessSwiperLLIllwoUU7Meta?.alias || [],
    redirect: ProcessSwiperLLIllwoUU7Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/ProcessSwiper.vue").then(m => m.default || m)
  },
  {
    name: TestimonialdSvW8oBJf0Meta?.name ?? "landing-mobile-app-1-components-Testimonial___ar",
    path: TestimonialdSvW8oBJf0Meta?.path ?? "/ar/landing/mobile-app-1/components/Testimonial",
    meta: TestimonialdSvW8oBJf0Meta || {},
    alias: TestimonialdSvW8oBJf0Meta?.alias || [],
    redirect: TestimonialdSvW8oBJf0Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCard5wbwh7r6fbMeta?.name ?? "landing-mobile-app-1-components-TestimonialCard___ar",
    path: TestimonialCard5wbwh7r6fbMeta?.path ?? "/ar/landing/mobile-app-1/components/TestimonialCard",
    meta: TestimonialCard5wbwh7r6fbMeta || {},
    alias: TestimonialCard5wbwh7r6fbMeta?.alias || [],
    redirect: TestimonialCard5wbwh7r6fbMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: datajGDHrgGeOkMeta?.name ?? "landing-mobile-app-1-data___ar",
    path: datajGDHrgGeOkMeta?.path ?? "/ar/landing/mobile-app-1/data",
    meta: datajGDHrgGeOkMeta || {},
    alias: datajGDHrgGeOkMeta?.alias || [],
    redirect: datajGDHrgGeOkMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/data.ts").then(m => m.default || m)
  },
  {
    name: indexSRaN2hBorqMeta?.name ?? "landing-mobile-app-1___ar",
    path: indexSRaN2hBorqMeta?.path ?? "/ar/landing/mobile-app-1",
    meta: indexSRaN2hBorqMeta || {},
    alias: indexSRaN2hBorqMeta?.alias || [],
    redirect: indexSRaN2hBorqMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/index.vue").then(m => m.default || m)
  },
  {
    name: AnchorNavigationbJNda9ZYcGMeta?.name ?? "landing-mobile-app-1-ThemeComponents-AnchorNavigation___ar",
    path: AnchorNavigationbJNda9ZYcGMeta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/AnchorNavigation",
    meta: AnchorNavigationbJNda9ZYcGMeta || {},
    alias: AnchorNavigationbJNda9ZYcGMeta?.alias || [],
    redirect: AnchorNavigationbJNda9ZYcGMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue").then(m => m.default || m)
  },
  {
    name: BreadcrumbpcudvthJY0Meta?.name ?? "landing-mobile-app-1-ThemeComponents-Breadcrumb___ar",
    path: BreadcrumbpcudvthJY0Meta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/Breadcrumb",
    meta: BreadcrumbpcudvthJY0Meta || {},
    alias: BreadcrumbpcudvthJY0Meta?.alias || [],
    redirect: BreadcrumbpcudvthJY0Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue").then(m => m.default || m)
  },
  {
    name: CustomSyntax5j544GQAWdMeta?.name ?? "landing-mobile-app-1-ThemeComponents-CustomSyntax___ar",
    path: CustomSyntax5j544GQAWdMeta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/CustomSyntax",
    meta: CustomSyntax5j544GQAWdMeta || {},
    alias: CustomSyntax5j544GQAWdMeta?.alias || [],
    redirect: CustomSyntax5j544GQAWdMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue").then(m => m.default || m)
  },
  {
    name: JarallaxqUgdiJscplMeta?.name ?? "landing-mobile-app-1-ThemeComponents-Jarallax___ar",
    path: JarallaxqUgdiJscplMeta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/Jarallax",
    meta: JarallaxqUgdiJscplMeta || {},
    alias: JarallaxqUgdiJscplMeta?.alias || [],
    redirect: JarallaxqUgdiJscplMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue").then(m => m.default || m)
  },
  {
    name: LightGalleryah8gNdmOiIMeta?.name ?? "landing-mobile-app-1-ThemeComponents-LightGallery___ar",
    path: LightGalleryah8gNdmOiIMeta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/LightGallery",
    meta: LightGalleryah8gNdmOiIMeta || {},
    alias: LightGalleryah8gNdmOiIMeta?.alias || [],
    redirect: LightGalleryah8gNdmOiIMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue").then(m => m.default || m)
  },
  {
    name: LoadingSpinnerSPihv8kjm2Meta?.name ?? "landing-mobile-app-1-ThemeComponents-LoadingSpinner___ar",
    path: LoadingSpinnerSPihv8kjm2Meta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/LoadingSpinner",
    meta: LoadingSpinnerSPihv8kjm2Meta || {},
    alias: LoadingSpinnerSPihv8kjm2Meta?.alias || [],
    redirect: LoadingSpinnerSPihv8kjm2Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue").then(m => m.default || m)
  },
  {
    name: ParallaxGSPX45pr1uMeta?.name ?? "landing-mobile-app-1-ThemeComponents-Parallax___ar",
    path: ParallaxGSPX45pr1uMeta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/Parallax",
    meta: ParallaxGSPX45pr1uMeta || {},
    alias: ParallaxGSPX45pr1uMeta?.alias || [],
    redirect: ParallaxGSPX45pr1uMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue").then(m => m.default || m)
  },
  {
    name: PasswordInputHDI349mbFvMeta?.name ?? "landing-mobile-app-1-ThemeComponents-PasswordInput___ar",
    path: PasswordInputHDI349mbFvMeta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/PasswordInput",
    meta: PasswordInputHDI349mbFvMeta || {},
    alias: PasswordInputHDI349mbFvMeta?.alias || [],
    redirect: PasswordInputHDI349mbFvMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue").then(m => m.default || m)
  },
  {
    name: RellaxIYD6FrDrVlMeta?.name ?? "landing-mobile-app-1-ThemeComponents-Rellax___ar",
    path: RellaxIYD6FrDrVlMeta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/Rellax",
    meta: RellaxIYD6FrDrVlMeta || {},
    alias: RellaxIYD6FrDrVlMeta?.alias || [],
    redirect: RellaxIYD6FrDrVlMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue").then(m => m.default || m)
  },
  {
    name: VanillaTilthvc7EBi3koMeta?.name ?? "landing-mobile-app-1-ThemeComponents-VanillaTilt___ar",
    path: VanillaTilthvc7EBi3koMeta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/VanillaTilt",
    meta: VanillaTilthvc7EBi3koMeta || {},
    alias: VanillaTilthvc7EBi3koMeta?.alias || [],
    redirect: VanillaTilthvc7EBi3koMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue").then(m => m.default || m)
  },
  {
    name: VideoPopup0IVuRDTRwAMeta?.name ?? "landing-mobile-app-1-ThemeComponents-VideoPopup___ar",
    path: VideoPopup0IVuRDTRwAMeta?.path ?? "/ar/landing/mobile-app-1/ThemeComponents/VideoPopup",
    meta: VideoPopup0IVuRDTRwAMeta || {},
    alias: VideoPopup0IVuRDTRwAMeta?.alias || [],
    redirect: VideoPopup0IVuRDTRwAMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue").then(m => m.default || m)
  },
  {
    name: indexUFaoH6FPGyMeta?.name ?? "landing-sales___ar",
    path: indexUFaoH6FPGyMeta?.path ?? "/ar/landing/sales",
    meta: indexUFaoH6FPGyMeta || {},
    alias: indexUFaoH6FPGyMeta?.alias || [],
    redirect: indexUFaoH6FPGyMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/sales/index.vue").then(m => m.default || m)
  },
  {
    name: indexKx063EsXi6Meta?.name ?? "landing-warranty-form___ar",
    path: indexKx063EsXi6Meta?.path ?? "/ar/landing/warranty-form",
    meta: indexKx063EsXi6Meta || {},
    alias: indexKx063EsXi6Meta?.alias || [],
    redirect: indexKx063EsXi6Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty-form/index.vue").then(m => m.default || m)
  },
  {
    name: AppFeaturezBnVBCHCvyMeta?.name ?? "landing-warranty-components-AppFeature___ar",
    path: AppFeaturezBnVBCHCvyMeta?.path ?? "/ar/landing/warranty/components/AppFeature",
    meta: AppFeaturezBnVBCHCvyMeta || {},
    alias: AppFeaturezBnVBCHCvyMeta?.alias || [],
    redirect: AppFeaturezBnVBCHCvyMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/AppFeature.vue").then(m => m.default || m)
  },
  {
    name: PricingX5hRnkNercMeta?.name ?? "landing-warranty-components-Pricing___ar",
    path: PricingX5hRnkNercMeta?.path ?? "/ar/landing/warranty/components/Pricing",
    meta: PricingX5hRnkNercMeta || {},
    alias: PricingX5hRnkNercMeta?.alias || [],
    redirect: PricingX5hRnkNercMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/Pricing.vue").then(m => m.default || m)
  },
  {
    name: Saas3HerowHIgJJW6FKMeta?.name ?? "landing-warranty-components-Saas3Hero___ar",
    path: Saas3HerowHIgJJW6FKMeta?.path ?? "/ar/landing/warranty/components/Saas3Hero",
    meta: Saas3HerowHIgJJW6FKMeta || {},
    alias: Saas3HerowHIgJJW6FKMeta?.alias || [],
    redirect: Saas3HerowHIgJJW6FKMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/Saas3Hero.vue").then(m => m.default || m)
  },
  {
    name: TestimonialDSdplPAPSvMeta?.name ?? "landing-warranty-components-Testimonial___ar",
    path: TestimonialDSdplPAPSvMeta?.path ?? "/ar/landing/warranty/components/Testimonial",
    meta: TestimonialDSdplPAPSvMeta || {},
    alias: TestimonialDSdplPAPSvMeta?.alias || [],
    redirect: TestimonialDSdplPAPSvMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardPjOAtwDWhJMeta?.name ?? "landing-warranty-components-TestimonialCard___ar",
    path: TestimonialCardPjOAtwDWhJMeta?.path ?? "/ar/landing/warranty/components/TestimonialCard",
    meta: TestimonialCardPjOAtwDWhJMeta || {},
    alias: TestimonialCardPjOAtwDWhJMeta?.alias || [],
    redirect: TestimonialCardPjOAtwDWhJMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: WorkBETETI8ZMtMeta?.name ?? "landing-warranty-components-Work___ar",
    path: WorkBETETI8ZMtMeta?.path ?? "/ar/landing/warranty/components/Work",
    meta: WorkBETETI8ZMtMeta || {},
    alias: WorkBETETI8ZMtMeta?.alias || [],
    redirect: WorkBETETI8ZMtMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/components/Work.vue").then(m => m.default || m)
  },
  {
    name: dataWcTVbE3j24Meta?.name ?? "landing-warranty-data___ar",
    path: dataWcTVbE3j24Meta?.path ?? "/ar/landing/warranty/data",
    meta: dataWcTVbE3j24Meta || {},
    alias: dataWcTVbE3j24Meta?.alias || [],
    redirect: dataWcTVbE3j24Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/data.ts").then(m => m.default || m)
  },
  {
    name: indexopsNP9FLiAMeta?.name ?? "landing-warranty___ar",
    path: indexopsNP9FLiAMeta?.path ?? "/ar/landing/warranty",
    meta: indexopsNP9FLiAMeta || {},
    alias: indexopsNP9FLiAMeta?.alias || [],
    redirect: indexopsNP9FLiAMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/index.vue").then(m => m.default || m)
  },
  {
    name: typekAeHIjH0mTMeta?.name ?? "landing-warranty-type___ar",
    path: typekAeHIjH0mTMeta?.path ?? "/ar/landing/warranty/type",
    meta: typekAeHIjH0mTMeta || {},
    alias: typekAeHIjH0mTMeta?.alias || [],
    redirect: typekAeHIjH0mTMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/landing/warranty/type.ts").then(m => m.default || m)
  },
  {
    name: ArticleCardeld0eBRKSHMeta?.name ?? "news-id-components-ArticleCard___ar",
    path: ArticleCardeld0eBRKSHMeta?.path ?? "/ar/news/:id()/components/ArticleCard",
    meta: ArticleCardeld0eBRKSHMeta || {},
    alias: ArticleCardeld0eBRKSHMeta?.alias || [],
    redirect: ArticleCardeld0eBRKSHMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/ArticleCard.vue").then(m => m.default || m)
  },
  {
    name: ArticlesnZ77JJ6N2sMeta?.name ?? "news-id-components-Articles___ar",
    path: ArticlesnZ77JJ6N2sMeta?.path ?? "/ar/news/:id()/components/Articles",
    meta: ArticlesnZ77JJ6N2sMeta || {},
    alias: ArticlesnZ77JJ6N2sMeta?.alias || [],
    redirect: ArticlesnZ77JJ6N2sMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/Articles.vue").then(m => m.default || m)
  },
  {
    name: CommentiFkFS2GFqCMeta?.name ?? "news-id-components-Comment___ar",
    path: CommentiFkFS2GFqCMeta?.path ?? "/ar/news/:id()/components/Comment",
    meta: CommentiFkFS2GFqCMeta || {},
    alias: CommentiFkFS2GFqCMeta?.alias || [],
    redirect: CommentiFkFS2GFqCMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/Comment.vue").then(m => m.default || m)
  },
  {
    name: CommentForm6E1bl4JERrMeta?.name ?? "news-id-components-CommentForm___ar",
    path: CommentForm6E1bl4JERrMeta?.path ?? "/ar/news/:id()/components/CommentForm",
    meta: CommentForm6E1bl4JERrMeta || {},
    alias: CommentForm6E1bl4JERrMeta?.alias || [],
    redirect: CommentForm6E1bl4JERrMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/CommentForm.vue").then(m => m.default || m)
  },
  {
    name: ContenttLUqNO3RMOMeta?.name ?? "news-id-components-Content___ar",
    path: ContenttLUqNO3RMOMeta?.path ?? "/ar/news/:id()/components/Content",
    meta: ContenttLUqNO3RMOMeta || {},
    alias: ContenttLUqNO3RMOMeta?.alias || [],
    redirect: ContenttLUqNO3RMOMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: Jarallax1R79W9n0ftMeta?.name ?? "news-id-components-Jarallax___ar",
    path: Jarallax1R79W9n0ftMeta?.path ?? "/ar/news/:id()/components/Jarallax",
    meta: Jarallax1R79W9n0ftMeta || {},
    alias: Jarallax1R79W9n0ftMeta?.alias || [],
    redirect: Jarallax1R79W9n0ftMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/Jarallax.vue").then(m => m.default || m)
  },
  {
    name: LightGallery37oKCSwomnMeta?.name ?? "news-id-components-LightGallery___ar",
    path: LightGallery37oKCSwomnMeta?.path ?? "/ar/news/:id()/components/LightGallery",
    meta: LightGallery37oKCSwomnMeta || {},
    alias: LightGallery37oKCSwomnMeta?.alias || [],
    redirect: LightGallery37oKCSwomnMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/LightGallery.vue").then(m => m.default || m)
  },
  {
    name: SinglePostFooterlOJIM5Sj4KMeta?.name ?? "news-id-components-SinglePostFooter___ar",
    path: SinglePostFooterlOJIM5Sj4KMeta?.path ?? "/ar/news/:id()/components/SinglePostFooter",
    meta: SinglePostFooterlOJIM5Sj4KMeta || {},
    alias: SinglePostFooterlOJIM5Sj4KMeta?.alias || [],
    redirect: SinglePostFooterlOJIM5Sj4KMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/SinglePostFooter.vue").then(m => m.default || m)
  },
  {
    name: SinglePostHerouDYE4aw2dCMeta?.name ?? "news-id-components-SinglePostHero___ar",
    path: SinglePostHerouDYE4aw2dCMeta?.path ?? "/ar/news/:id()/components/SinglePostHero",
    meta: SinglePostHerouDYE4aw2dCMeta || {},
    alias: SinglePostHerouDYE4aw2dCMeta?.alias || [],
    redirect: SinglePostHerouDYE4aw2dCMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/components/SinglePostHero.vue").then(m => m.default || m)
  },
  {
    name: data79eIOOBPQkMeta?.name ?? "news-id-data___ar",
    path: data79eIOOBPQkMeta?.path ?? "/ar/news/:id()/data",
    meta: data79eIOOBPQkMeta || {},
    alias: data79eIOOBPQkMeta?.alias || [],
    redirect: data79eIOOBPQkMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/data.ts").then(m => m.default || m)
  },
  {
    name: indexFCcGGSFe6HMeta?.name ?? "news-id___ar",
    path: indexFCcGGSFe6HMeta?.path ?? "/ar/news/:id()",
    meta: indexFCcGGSFe6HMeta || {},
    alias: indexFCcGGSFe6HMeta?.alias || [],
    redirect: indexFCcGGSFe6HMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: typeZfo3F7NOt5Meta?.name ?? "news-id-type___ar",
    path: typeZfo3F7NOt5Meta?.path ?? "/ar/news/:id()/type",
    meta: typeZfo3F7NOt5Meta || {},
    alias: typeZfo3F7NOt5Meta?.alias || [],
    redirect: typeZfo3F7NOt5Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/[id]/type.ts").then(m => m.default || m)
  },
  {
    name: BlogCardirwuVjodgHMeta?.name ?? "news-components-BlogCard___ar",
    path: BlogCardirwuVjodgHMeta?.path ?? "/ar/news/components/BlogCard",
    meta: BlogCardirwuVjodgHMeta || {},
    alias: BlogCardirwuVjodgHMeta?.alias || [],
    redirect: BlogCardirwuVjodgHMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/components/BlogCard.vue").then(m => m.default || m)
  },
  {
    name: ContentPABGg5TfN3Meta?.name ?? "news-components-Content___ar",
    path: ContentPABGg5TfN3Meta?.path ?? "/ar/news/components/Content",
    meta: ContentPABGg5TfN3Meta || {},
    alias: ContentPABGg5TfN3Meta?.alias || [],
    redirect: ContentPABGg5TfN3Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/components/Content.vue").then(m => m.default || m)
  },
  {
    name: Footer8YYBjbBsfEMeta?.name ?? "news-components-Footer___ar",
    path: Footer8YYBjbBsfEMeta?.path ?? "/ar/news/components/Footer",
    meta: Footer8YYBjbBsfEMeta || {},
    alias: Footer8YYBjbBsfEMeta?.alias || [],
    redirect: Footer8YYBjbBsfEMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/components/Footer.vue").then(m => m.default || m)
  },
  {
    name: PaginationMACmWx2ISEMeta?.name ?? "news-components-Pagination___ar",
    path: PaginationMACmWx2ISEMeta?.path ?? "/ar/news/components/Pagination",
    meta: PaginationMACmWx2ISEMeta || {},
    alias: PaginationMACmWx2ISEMeta?.alias || [],
    redirect: PaginationMACmWx2ISEMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/components/Pagination.vue").then(m => m.default || m)
  },
  {
    name: dataXAxu47lnqXMeta?.name ?? "news-data___ar",
    path: dataXAxu47lnqXMeta?.path ?? "/ar/news/data",
    meta: dataXAxu47lnqXMeta || {},
    alias: dataXAxu47lnqXMeta?.alias || [],
    redirect: dataXAxu47lnqXMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/data.ts").then(m => m.default || m)
  },
  {
    name: index2PcF38ZZeFMeta?.name ?? "news___ar",
    path: index2PcF38ZZeFMeta?.path ?? "/ar/news",
    meta: index2PcF38ZZeFMeta || {},
    alias: index2PcF38ZZeFMeta?.alias || [],
    redirect: index2PcF38ZZeFMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: single_45newsf9S7Y48DYAMeta?.name ?? "news-single-news___ar",
    path: single_45newsf9S7Y48DYAMeta?.path ?? "/ar/news/single-news",
    meta: single_45newsf9S7Y48DYAMeta || {},
    alias: single_45newsf9S7Y48DYAMeta?.alias || [],
    redirect: single_45newsf9S7Y48DYAMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/single-news.vue").then(m => m.default || m)
  },
  {
    name: typesaWI5m2R9RMeta?.name ?? "news-type___ar",
    path: typesaWI5m2R9RMeta?.path ?? "/ar/news/type",
    meta: typesaWI5m2R9RMeta || {},
    alias: typesaWI5m2R9RMeta?.alias || [],
    redirect: typesaWI5m2R9RMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/news/type.ts").then(m => m.default || m)
  },
  {
    name: indextmrBhIBcQqMeta?.name ?? "payment-redirect___ar",
    path: indextmrBhIBcQqMeta?.path ?? "/ar/payment/redirect",
    meta: indextmrBhIBcQqMeta || {},
    alias: indextmrBhIBcQqMeta?.alias || [],
    redirect: indextmrBhIBcQqMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/payment/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: ContentAXnGm6LbN4Meta?.name ?? "privacy-policy-components-Content___ar",
    path: ContentAXnGm6LbN4Meta?.path ?? "/ar/privacy-policy/components/Content",
    meta: ContentAXnGm6LbN4Meta || {},
    alias: ContentAXnGm6LbN4Meta?.alias || [],
    redirect: ContentAXnGm6LbN4Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/privacy-policy/components/Content.vue").then(m => m.default || m)
  },
  {
    name: SinglePostHeronOsIzI9KCpMeta?.name ?? "privacy-policy-components-SinglePostHero___ar",
    path: SinglePostHeronOsIzI9KCpMeta?.path ?? "/ar/privacy-policy/components/SinglePostHero",
    meta: SinglePostHeronOsIzI9KCpMeta || {},
    alias: SinglePostHeronOsIzI9KCpMeta?.alias || [],
    redirect: SinglePostHeronOsIzI9KCpMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/privacy-policy/components/SinglePostHero.vue").then(m => m.default || m)
  },
  {
    name: indexmDPGqZBdE7Meta?.name ?? "privacy-policy___ar",
    path: indexmDPGqZBdE7Meta?.path ?? "/ar/privacy-policy",
    meta: indexmDPGqZBdE7Meta || {},
    alias: indexmDPGqZBdE7Meta?.alias || [],
    redirect: indexmDPGqZBdE7Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexJi7Ajbh5EzMeta?.name ?? "sales-agent-form___ar",
    path: indexJi7Ajbh5EzMeta?.path ?? "/ar/sales-agent-form",
    meta: indexJi7Ajbh5EzMeta || {},
    alias: indexJi7Ajbh5EzMeta?.alias || [],
    redirect: indexJi7Ajbh5EzMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/sales-agent-form/index.vue").then(m => m.default || m)
  },
  {
    name: indexqOHKyRbo90Meta?.name ?? "sales-agent-form2___ar",
    path: indexqOHKyRbo90Meta?.path ?? "/ar/sales-agent-form2",
    meta: indexqOHKyRbo90Meta || {},
    alias: indexqOHKyRbo90Meta?.alias || [],
    redirect: indexqOHKyRbo90Meta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/sales-agent-form2/index.vue").then(m => m.default || m)
  },
  {
    name: ContentXraZyiSuWqMeta?.name ?? "terms-and-condition-components-Content___ar",
    path: ContentXraZyiSuWqMeta?.path ?? "/ar/terms-and-condition/components/Content",
    meta: ContentXraZyiSuWqMeta || {},
    alias: ContentXraZyiSuWqMeta?.alias || [],
    redirect: ContentXraZyiSuWqMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/terms-and-condition/components/Content.vue").then(m => m.default || m)
  },
  {
    name: SinglePostHeroohNBG9eu5SMeta?.name ?? "terms-and-condition-components-SinglePostHero___ar",
    path: SinglePostHeroohNBG9eu5SMeta?.path ?? "/ar/terms-and-condition/components/SinglePostHero",
    meta: SinglePostHeroohNBG9eu5SMeta || {},
    alias: SinglePostHeroohNBG9eu5SMeta?.alias || [],
    redirect: SinglePostHeroohNBG9eu5SMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/terms-and-condition/components/SinglePostHero.vue").then(m => m.default || m)
  },
  {
    name: indexOJ58p4k8XsMeta?.name ?? "terms-and-condition___ar",
    path: indexOJ58p4k8XsMeta?.path ?? "/ar/terms-and-condition",
    meta: indexOJ58p4k8XsMeta || {},
    alias: indexOJ58p4k8XsMeta?.alias || [],
    redirect: indexOJ58p4k8XsMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/terms-and-condition/index.vue").then(m => m.default || m)
  },
  {
    name: indexDoBjozyl6iMeta?.name ?? "test___ar",
    path: indexDoBjozyl6iMeta?.path ?? "/ar/test",
    meta: indexDoBjozyl6iMeta || {},
    alias: indexDoBjozyl6iMeta?.alias || [],
    redirect: indexDoBjozyl6iMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: indexbriGF3aFMMMeta?.name ?? "trim___ar",
    path: indexbriGF3aFMMMeta?.path ?? "/ar/trim",
    meta: indexbriGF3aFMMMeta || {},
    alias: indexbriGF3aFMMMeta?.alias || [],
    redirect: indexbriGF3aFMMMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/trim/index.vue").then(m => m.default || m)
  },
  {
    name: indexowFwUi0ETTMeta?.name ?? "warranty-form___ar",
    path: indexowFwUi0ETTMeta?.path ?? "/ar/warranty-form",
    meta: indexowFwUi0ETTMeta || {},
    alias: indexowFwUi0ETTMeta?.alias || [],
    redirect: indexowFwUi0ETTMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/warranty-form/index.vue").then(m => m.default || m)
  },
  {
    name: indextZv3K34zOnMeta?.name ?? "warranty-form2___ar",
    path: indextZv3K34zOnMeta?.path ?? "/ar/warranty-form2",
    meta: indextZv3K34zOnMeta || {},
    alias: indextZv3K34zOnMeta?.alias || [],
    redirect: indextZv3K34zOnMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/warranty-form2/index.vue").then(m => m.default || m)
  },
  {
    name: indexpJzrIC3hAZMeta?.name ?? "warranty___ar",
    path: indexpJzrIC3hAZMeta?.path ?? "/ar/warranty",
    meta: indexpJzrIC3hAZMeta || {},
    alias: indexpJzrIC3hAZMeta?.alias || [],
    redirect: indexpJzrIC3hAZMeta?.redirect,
    component: () => import("/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/pages/warranty/index.vue").then(m => m.default || m)
  }
]