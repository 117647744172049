export default defineNuxtPlugin(() => {
  // const userAuth = useCookie('token')
  const config = useRuntimeConfig()

  const $api = $fetch.create({
    baseURL: config.public.apiURL,
    onRequest({ request, options, error }) {
      const { access_token } = localStorage.user ? JSON.parse(localStorage.user) : {}
      options.headers = options.headers || {}
      options.headers.Authorization = `Bearer ${access_token}`
      // if (userAuth.value) {
      //   // Add Authorization header
      // options.headers = options.headers || {}
      //   options.headers.Authorization = `Bearer ${userAuth.value}`
      // }
    },
    onResponse({ response }) {
      // response._data = new myBusinessResponse(response._data)
    },
    onResponseError({ response }) {
      // if (response.status === 401) {
      //   console.log('navigate to login ')
      //   // return navigateTo('/login')
      // }
    },
  })
  // Expose to useNuxtApp().$api
  return {
    provide: {
      api: $api,
    },
  }
})
