import brand1Img from '../../../assets/images/brands/01.svg'
import brand2Img from '../../../assets/images/brands/02.svg'
import brand3Img from '../../../assets/images/brands/03.svg'
import brand4Img from '../../../assets/images/brands/04.svg'
import brand5Img from '../../../assets/images/brands/05.svg'
import brand6Img from '../../../assets/images/brands/06.svg'

import step from '@/assets/images/landing/warranty/step.png'
import step2 from '@/assets/images/landing/warranty/step2.png'
import step3 from '@/assets/images/landing/warranty/step3.png'
import step4 from '@/assets/images/landing/warranty/step4.png'

import avatar40Img from '../../../assets/images/avatar/40.jpg'
import avatar6Img from '../../../assets/images/avatar/06.jpg'
import avatar41Img from '../../../assets/images/avatar/41.jpg'
import avatar5Img from '../../../assets/images/avatar/05.jpg'

import chatImg from '@/assets/images/landing/inspection/features/test.png'
import analyticsImg from '@/assets/images/landing/inspection/features/2.png'
import bellImg from '@/assets/images/landing/inspection/features/presentation.png'
import tasksImg from '@/assets/images/landing/inspection/features/chat.png'

import type { BrandType, FeatureType, WorkFlowType, TestimonialType, UserType, } from './type'

type FaqsType = {
  question: string
  answers: string[]
}
export const featuresData: FeatureType[] = [
  {
    image: chatImg,
    title: 'زيادة دخلك',
    description:
      'احصل على دخل يصل إلى اكثر من 12,000 الف ريال في الشهر عن طريق تسويقك لرمزك الترويجي الخاص بك لخدمات فحص وضمان كار ترست المميزة',
  },
  {
    image: analyticsImg,
    title: 'تدريب متخصص',
    description:
      'عند اعتمادك كمسوق، يمكنك الانضمام لدورات تدريبية مقدمة من خبراء كار ترست تساعدك في تسويق ونشر خدمات كار ترست بكل سهولة!',
  },
  {
    image: bellImg,
    title: 'خدمات مطلوبة',
    description: 'الجميع في سوق المركبات المستعملة بحاجة الى عمليات فحص وضمان موثوقه للمساعدة في اتخاذ القرار السليم بالشراء'
    // 'سوق السيارات كبير وفحص كار ترست معروف بدقته وجودة الخدمة. كل اللي تحتاجه إنك تقول لهم عننا',
  },
  {
    image: tasksImg,
    title: 'عملاءك معروفين',
    description:
      'تقدر تعرف العملاء المستفيدين من خدمات كارترست عن طريق الرمز الخاص بك',
  }
]

export const featuresData3: FeatureType[] = [
  {
    image: step,
    title: 'سجل',
    description: 'سجل معلوماتك لكي تنضم لعائلة مسوقي برنامج كار ترست',
  },
  {
    image: step2,
    title: 'احصل  على الرمز',
    description:
      'احصل على رمزك الخاص بعد الموافقة في برنامج مسوق كار ترست '
  },
  {
    image: step3,
    title: 'سوّق',
    description: 'شارك مع عملائك الرمز الترويجي الخاص بك',
  },
  {
    image: step4,
    title: 'اكسب',
    description: 'اكسب عمولتك مع كل عملية شراء من خلال منصات خدمات كار ترست',
  },
]


export const brandData: BrandType[] = [
  {
    image: brand1Img
  },
  {
    image: brand2Img
  },
  {
    image: brand3Img
  },
  {
    image: brand4Img
  },
  {
    image: brand5Img
  },
  {
    image: brand6Img
  }
]

export const workFlowData: WorkFlowType[] = [
  {
    title: 'Registration',
    description: 'Massa enim libero dictumst consectetur in convallis lobortis'
  },
  {
    title: 'Identity verification',
    description: 'At ultricies id non quisque integer eget velit. Facilisis enim'
  },
  {
    title: 'Start trading',
    description: 'Pellentesque rhoncus viverra vestibulum, purus purus'
  },
  {
    title: 'Earn with Silicon',
    description: 'Massa enim libero dictumst consectetur in convallis lobortis'
  }
]

export const userData: UserType[] = [
  {
    image: avatar40Img,
    name: 'Jenny Wilson',
    role: 'Founder of Lorem Company'
  },
  {
    image: avatar6Img,
    name: 'Wade Warren',
    role: 'CEO of Ipsum Company'
  },
  {
    image: avatar41Img,
    name: 'Ralph Edwards',
    role: 'Founder of the Agency'
  },
  {
    image: avatar5Img,
    name: 'Ralph Edwards',
    role: 'CEO of Dolor Ltd.'
  }
]

export const testimonialData: TestimonialType[] = [
  {
    comment:
      "This app has completely transformed the way I approach my finances. It's packed with useful features and it's helped me stay on top of my finances and reach my financial goals.",
    rating: 5,
    user: userData[0]
  },
  {
    comment: `I absolutely love using this app! It's user-friendly interface makes it incredibly easy to track my spending and budget accordingly. I highly recommend it to anyone looking to improve their financial management skills.`,
    rating: 4,
    user: userData[1]
  },
  {
    comment: `I've tried many different apps, but this one stands out as the best. Its intuitive interface, helpful tools, and insightful reports have made it an essential tool for managing my money.`,
    rating: 5,
    user: userData[2]
  },
  {
    comment:
      "This app has completely transformed the way I approach my finances. It's packed with useful features and it's helped me stay on top of my finances and reach my financial goals.",
    rating: 4,
    user: userData[3]
  }
]

export const faqsData: FaqsType[] = [
  {
    question: `ماهو برنامج التسويق بالعمولة في كارترست؟`,
    answers: [
      `برنامج هدفه إيصال خدمات كار ترست لكل شخص يحتاج الفحص قبل الشراء او الضمان الممتد. وجنوده هم أنتم. وجهودكم يمكن تتبعها ومكافأتكم عليها بعمولتكم المستحقة`
    ]
  },
  {
    question: `ماهي العمولة المستحقة لي؟`,
    answers: [
      `جميع العمولات مشروحة في جدول العمولات مقابل كل خدمة`
    ]
  },
  {
    question: `كيف أصبح مسوق بالعمولة؟`,
    answers: [
      `سجل عبر الرابط الخاص بالانضمام واملأ المعلومات اللازمة، وسيصلك بعدها مباشرة الرابط والكود الخاص بك كمسوق بالعمولة`
    ]
  }, {
    question: `هل توجد شروط للانضمام؟`,
    answers: [
      `شرطنا الوحيد هو رغبتك بالانضمام للبرنامج!`
    ]
  }, {
    question: `هل هناك أي تكاليف مرتبطة بالانضمام الي البرنامج؟`,
    answers: [
      `الانضمام مجاني تمامًا. وبتكسب معه عمولة ودورات تسويق متخصصة`
    ]
  }, {
    question: `كيف أتمكن من تتبع المبيعات؟`,
    answers: [
      `كل مسوّق ينضم للبرنامج له رابط خاص وكود خاص. عند استخدام عميلك الكود في خدمات كارترست. يتم رصد العملية وإسنادها لحسابك`
    ]
  }, {
    question: `كم مرة يتم دفع العمولات؟`,
    answers: [
      `نحوّل لك العمولات كل شهر على حسابك البنكي`
    ]
  }, {
    question: `هل هناك أي دعم مقدم للمسوقين؟`,
    answers: [
      `فريقنا موجود لإجابة كل سؤال يجي على بالك. حتى لو كان “بكم زيت الفراميل؟`
    ]
  }, {
    question: `هل يوجد كود خصم للمسوق؟`,
    answers: [
      `لكل مسوق إمكانية الخصم على سعر الباقة، تواصل معنا للحصول على الخصم`
    ]
  }
]


