import revive_payload_client_4sVQNw7RlN from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3rw1Iqhpn2 from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import useDirectives_W0tXrzvhuP from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import i18n_yfWm7jX06p from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_GFfDXud5Cr from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/plugins/api.ts";
import globals_qEnHRCYwqu from "/Users/ahmedyousuf/development/projects/CarTrust/cartrust_web/plugins/globals.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_3rw1Iqhpn2,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  api_GFfDXud5Cr,
  globals_qEnHRCYwqu
]