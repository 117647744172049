import batteryImg from '../img/landing/product/benefits/battery.svg'
import audioImg from '../img/landing/product/benefits/audio.svg'
import computerMicroImg from '../img/landing/product/benefits/computer-microprocessor.svg'
import imageImg from '../img/landing/product/benefits/image.png'
import ledLightImg from '../img/landing/product/benefits/led-light.svg'
import monitorImg from '../img/landing/product/benefits/monitor.svg'
import thermometerImg from '../img/landing/product/benefits/thermometer.svg'
import zoomInImg from '../img/landing/product/benefits/zoom-in.svg'
import droneImg from '../img/landing/product/benefits/drone.svg'
import gallery1Img from '../img/landing/product/gallery/01.jpg'
import gallery2Img from '../img/landing/product/gallery/02.jpg'
import gallery3Img from '../img/landing/product/gallery/03.jpg'
import gallery4Img from '../img/landing/product/gallery/04.jpg'
import gallery5Img from '../img/landing/product/gallery/05.jpg'
import gallery6Img from '../img/landing/product/gallery/06.jpg'
import slider1Img from '../img/landing/product/hero/slider/01.jpg'
import slider2Img from '../img/landing/product/hero/slider/02.jpg'
import slider3Img from '../img/landing/product/hero/slider/03.jpg'
import heroImg from '../img/landing/product/hero/image.png'
import bgImg from '../img/landing/product/hero/bg.jpg'
import specs1Img from '../img/landing/product/specs/01.png'
import specs2Img from '../img/landing/product/specs/02.png'
import specs3Img from '../img/landing/product/specs/03.png'
import specs4Img from '../img/landing/product/specs/04.png'
import videoCoverImg from '../img/landing/product/video-cover.jpg'
import product1Img from '../img/landing/product/colors/01.png'
import product2Img from '../img/landing/product/colors/02.png'
import btcImg from '../img/landing/app-showcase-2/crypto/btc.png'
import btcGraphImg from '../img/landing/app-showcase-2/crypto/graphs/btc.svg'
import usdtImg from '../img/landing/app-showcase-2/crypto/usdt.png'
import usdtGraphImg from '../img/landing/app-showcase-2/crypto/graphs/usdt.svg'
import ethImg from '../img/landing/app-showcase-2/crypto/eth.png'
import ethGraphImg from '../img/landing/app-showcase-2/crypto/graphs/eth.svg'
import dotImg from '../img/landing/app-showcase-2/crypto/dot.png'
import dotGraphImg from '../img/landing/app-showcase-2/crypto/graphs/dot.svg'
import paymentImg from '../img/landing/app-showcase-2/features/payments.svg'
import cashBackImg from '../img/landing/app-showcase-2/features/cashback.svg'
import statisticsImg from '../img/landing/app-showcase-2/features/statistics.svg'
import securityImg from '../img/landing/app-showcase-2/features/security.svg'
import statsImg from '../img/landing/app-showcase-2/stats.jpg'
import layer1Img from '../img/landing/app-showcase-2/hero/layer01.png'
import layer2Img from '../img/landing/app-showcase-2/hero/layer02.png'
import layer3Img from '../img/landing/app-showcase-2/hero/layer03.png'
import layer4Img from '../img/landing/app-showcase-3/hero/layer04.png'
import layer5Img from '../img/landing/app-showcase-3/hero/layer05.png'
import layer6Img from '../img/landing/app-showcase-3/hero/layer06.png'
import appStoreLightImg from '../img/market/appstore-light.svg'
import appStoreDarkImg from '../img/market/appstore-dark.svg'
import googlePlayLightImg from '../img/market/googleplay-light.svg'
import googlePlayDarkImg from '../img/market/googleplay-dark.svg'
import deviceImg from '../img/landing/app-showcase-2/devices.png'
import chatImg from '../img/landing/app-showcase-2/icons/chat.svg'
import faqsImg from '../img/landing/app-showcase-2/icons/faq.svg'
import emailImg from '../img/landing/app-showcase-2/icons/email.svg'
import bussinesImg from '../img/landing/app-showcase-3/categories/business.png'
import startupsImg from '../img/landing/app-showcase-3/categories/startups.png'
import studentImg from '../img/landing/app-showcase-3/categories/students.png'
import payment3Img from '../img/landing/app-showcase-3/features/payment.svg'
import usersImg from '../img/landing/app-showcase-3/features/users.svg'
import statistics3Img from '../img/landing/app-showcase-3/features/statistics.svg'
import supportImg from '../img/landing/app-showcase-3/features/support.svg'
import cashbackImg from '../img/landing/app-showcase-3/features/cashback.svg'
import security3Img from '../img/landing/app-showcase-3/features/security.svg'
import envatoGaryImg from '../img/landing/app-showcase-3/integrations/envato-gray.svg'
import envatoLightImg from '../img/landing/app-showcase-3/integrations/envato-color-light.svg'
import envatoDarkImg from '../img/landing/app-showcase-3/integrations/envato-color-dark.svg'
import angularGrayImg from '../img/landing/app-showcase-3/integrations/angular-gray.svg'
import angularDarkImg from '../img/landing/app-showcase-3/integrations/angular-color-dark.svg'
import angularLightImg from '../img/landing/app-showcase-3/integrations/angular-color-light.svg'
import laravelImg from '../img/landing/app-showcase-3/integrations/laravel-color.svg'
import laravelGrayImg from '../img/landing/app-showcase-3/integrations/laravel-gray.svg'
import magnetoDarkImg from '../img/landing/app-showcase-3/integrations/magento-color-dark.svg'
import magnetoLightImg from '../img/landing/app-showcase-3/integrations/magento-color-light.svg'
import magnetoGrayImg from '../img/landing/app-showcase-3/integrations/magento-gray.svg'
import pingdomGrayImg from '../img/landing/app-showcase-3/integrations/pingdom-gray.svg'
import pingdomDarkImg from '../img/landing/app-showcase-3/integrations/pingdom-color-dark.svg'
import pingdomLightImg from '../img/landing/app-showcase-3/integrations/pingdom-color-light.svg'
import woocommerceGrayImg from '../img/landing/app-showcase-3/integrations/woocommerce-gray.svg'
import woocommerceLightImg from '../img/landing/app-showcase-3/integrations/woocommerce-color-light.svg'
import woocommerceDarkImg from '../img/landing/app-showcase-3/integrations/woocommerce-color-dark.svg'
import palmBranchLeftLightImg from '../img/landing/app-showcase-3/palm-branch-left-light.svg'
import palmBranchLeftDarkImg from '../img/landing/app-showcase-3/palm-branch-left-dark.svg'
import palmBranchRightLightImg from '../img/landing/app-showcase-3/palm-branch-right-light.svg'
import palmBranchRightDarkImg from '../img/landing/app-showcase-3/palm-branch-right-dark.svg'
import screenImg from '../img/landing/app-showcase-3/screens.png'
import step1Img from '../img/landing/app-showcase-3/steps/01.png'
import step2Img from '../img/landing/app-showcase-3/steps/02.png'
import step3Img from '../img/landing/app-showcase-3/steps/03.png'
import screen1Img from '../img/landing/app-showcase-1/features/screen01.png'
import screen2Img from '../img/landing/app-showcase-1/features/screen02.png'
import screen3Img from '../img/landing/app-showcase-1/features/screen03.png'
import screen4Img from '../img/landing/app-showcase-1/features/screen04.png'
import paymentsImg from '../img/landing/app-showcase-1/features/payments.svg'
import phoneImg from '../img/landing/app-showcase-1/features/phone.png'
import cardImg from '../img/landing/app-showcase-1/features/card.png'

export {
  batteryImg,
  audioImg,
  computerMicroImg,
  imageImg,
  ledLightImg,
  monitorImg,
  thermometerImg,
  zoomInImg,
  droneImg,
  gallery1Img,
  gallery2Img,
  gallery3Img,
  gallery4Img,
  gallery5Img,
  gallery6Img,
  slider1Img,
  slider2Img,
  slider3Img,
  heroImg,
  bgImg,
  specs1Img,
  specs2Img,
  specs3Img,
  specs4Img,
  videoCoverImg,
  product1Img,
  product2Img,
  btcGraphImg,
  btcImg,
  usdtGraphImg,
  ethGraphImg,
  dotGraphImg,
  dotImg,
  ethImg,
  usdtImg,
  paymentImg,
  cashBackImg,
  statisticsImg,
  securityImg,
  statsImg,
  layer1Img,
  layer2Img,
  layer3Img,
  layer4Img,
  layer5Img,
  layer6Img,
  appStoreDarkImg,
  appStoreLightImg,
  googlePlayDarkImg,
  googlePlayLightImg,
  deviceImg,
  chatImg,
  faqsImg,
  emailImg,
  bussinesImg,
  startupsImg,
  studentImg,
  payment3Img,
  usersImg,
  statistics3Img,
  supportImg,
  cashbackImg,
  security3Img,
  envatoDarkImg,
  envatoGaryImg,
  envatoLightImg,
  laravelGrayImg,
  laravelImg,
  angularDarkImg,
  angularGrayImg,
  angularLightImg,
  magnetoDarkImg,
  magnetoGrayImg,
  magnetoLightImg,
  pingdomDarkImg,
  pingdomGrayImg,
  pingdomLightImg,
  woocommerceDarkImg,
  woocommerceGrayImg,
  woocommerceLightImg,
  palmBranchLeftDarkImg,
  palmBranchLeftLightImg,
  palmBranchRightDarkImg,
  palmBranchRightLightImg,
  screenImg,
  step1Img,
  step2Img,
  step3Img,
  screen1Img,
  screen2Img,
  screen3Img,
  screen4Img,
  paymentsImg,
  phoneImg,
  cardImg,
}
